<template>
  <div
    id="register"
    class="bg-white h-full container flex flex-col items-center mx-auto"
  >
    <Header text="Portal Access"/>
    <div class="w-full h-full flex bg-white lg:p-12">
      <div class="h-full w-full flex lg:flex-row flex-col">
        <div
          class="lg:w-1/2 flex items-center justify-center lg:order-1 order-2"
        >
          <img class="my-8" src="../assets/Logo_Tall.png" />
        </div>
        <div
          class="lg:w-1/2 flex flex-col justify-center lg:order-2 order-1"
          style="background-color: #ffd67a"
        >
          <div class="flex flex-col p-6">
            <p>To Register For Customer Portal Access</p>
            <p>Please Contact:</p>
            <strong>sales@cornfieldeng.co.uk</strong>
            <p class="text-sm font-semibold my-2 pt-1 mb-0">
              <router-link
                to="/"
                class="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
                >Return To Sign In</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
  name: "Register",
  components: { Header },
};
</script>
